*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

body,
button,
html,
input {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.box {
  padding: 1em;
}

.box-small {
  padding: 10px;
}

.box-scroll {
  padding: 1em 0 1em 1em;
}

input {
  width: 100%;
  padding: 1em;
  outline: none;
  border: 1px solid #f2f2f2;
}

.message-list {
  margin: 0;
  padding: 0;
}

.message-list li {
  padding: 0.2em;
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: #f2f2f2;
}

/* Our Grid Code */

.app-layout {
  display: grid;
  height: 100vh;
  grid-template-columns: auto 200px 1fr;
  grid-template-rows: auto auto 1fr auto;
}

.appbar {
  /*background-color: #f6f9fb; */
  /*background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);*/
  background: linear-gradient(-45deg, #e9defa, #fbfcdb);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}
.workspace {
  background: linear-gradient(-45deg, #e9defa, #fbfcdb);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}
.channels {
  /*background-color: #f6f9fb; */
  /*background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);*/
  background: linear-gradient(-45deg, #e9defa, #fbfcdb);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}
.write {
  background-color: #f6f9fb;
}

.appbar {
  grid-column: 2 / 4;
  grid-row: 1;
  outline-color: #3c4257;
  color: #3c4257;
  border-bottom: 1px solid #f0f3f6;
}

.workspace {
  grid-column: 1;
  grid-row: 1 / 5;
  overflow-y: auto;
  outline-color: #3c4257;
  border: 1px solid #f0f3f6;
}

.workspace-item {
  color: #869ab8;
}

.channels {
  grid-column: 2;
  grid-row: 2 / 5;
  overflow-y: auto;
  padding-right: 0;
  padding-left: 0;
  outline-color: #3c4257;
  border-right: 1px solid #f0f3f6;
  color: #3c4257;
}

.header-panel {
  grid-column: 3;
  grid-row: 2;
  color: #333;
  border-bottom: 1px solid #f2f2f2;
}

.messages {
  grid-column: 3;
  grid-row: 3;
  overflow-y: auto;
}

.write {
  grid-column: 3;
  grid-row: 4;
}

.participant {
  border-radius: 0px;
  display: inline-block;
}

video {
  width: 100%;
  object-fit: contain;
  max-width: 600px;
  display: block;
  margin: 0 auto;
}

.member-app-layout {
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr auto;
}

.member-contents-1 {
  grid-column: 1;
  grid-row: 1;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.member-contents-2 {
  grid-column: 1;
  grid-row: 2;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.member-action {
  grid-column: 1;
  grid-row: 3;
}

.ui.mini.search.search-box > .ui.left.icon.input > input {
  width: 300px;
}

.bg-grad {
  background: linear-gradient(-45deg, #e9defa, #fbfcdb);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
